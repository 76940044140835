import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../../../components/ExternalLink';
import LeadParagraph from '../../../../components/LeadParagraph';
import Image from '../../../../components/Image';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "mikä-on-hds-20",
      "style": {
        "position": "relative"
      }
    }}>{`Mikä on HDS 2.0?`}<a parentName="h1" {...{
        "href": "#mik%C3%A4-on-hds-20",
        "aria-label": "mikä on hds 20 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  HDS 2.0 -versio on suuri (major) julkaisu. Julkaisun päätavoite on yhdistää HDS:n ja Hel.fi -projektin visuaalista tyyliä. HDS komponentit siirtyvät käyttämään uusia kirjasintyylejä ja monet komponenteista päivittyvät vastaamaan Hel.fi-tyylejä.
    </LeadParagraph>
    <p>{`Tämä artikkeli auttaa ymmärtämään, mikä HDS 2.0 -versiossa on muuttunut ja miten muutokset vaikuttavat projektiisi. Jos etsit ohjeita HDS versiosta 1 versioon 2 siirtymiseen, katso `}<a parentName="p" {...{
        "href": "/getting-started/hds-2.0/migrating-to-2.0"
      }}>{`2.0 päivitysopas (englanniksi)`}</a>{`.`}</p>
    <h2 {...{
      "id": "mikä-muuttuu-tässä-versiossa",
      "style": {
        "position": "relative"
      }
    }}>{`Mikä muuttuu tässä versiossa?`}<a parentName="h2" {...{
        "href": "#mik%C3%A4-muuttuu-t%C3%A4ss%C3%A4-versiossa",
        "aria-label": "mikä muuttuu tässä versiossa permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`HDS 2.0 -päivityksessä tulee rikkovia muutoksia. Suurin osa isoista muutoksista ovat kuitenkin täysin visuaalisia. Komponenttien rajapintoihin (API) ei tule muutoksia muutamaa poistettua React-teemamuuttujaa lukuunottamatta.`}</p>
    <h3 {...{
      "id": "kirjasinpäivitykset",
      "style": {
        "position": "relative"
      }
    }}>{`Kirjasinpäivitykset`}<a parentName="h3" {...{
        "href": "#kirjasinp%C3%A4ivitykset",
        "aria-label": "kirjasinpäivitykset permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS on nyt siirtynyt käyttämään `}<a parentName="p" {...{
        "href": "/foundation/design-tokens/typography"
      }}>{`uusia kirjasintyylejä (englanniksi)`}</a>{`, jotka ovat tulleet monille tutuiksi Hel.fi-uudistuksen kautta. Merkittävin muutos on tehty otsikkofonttien paksuuteen. Suuret otsikot käyttävät nyt regular-paksuutta bold-paksuuden sijaan. Jatkossa vain pienimmät otsikkotyylit käyttävät bold-paksuutta. Fonttikokoja on myös jonkin verran muutettu, jotta otsikkoskaala olisi sulavampi. Otsikkotasojen maksimimäärää on samalla nostettu viidestä kuuteen. Ja kuten aikaisemminkin, mobiililaitteille on tarjolla erillinen otsikkoskaala. `}</p>
    <p>{`Tärkeää on huomata, että otsikkotyylien muutos aiheuttaa muutoksia moniin HDS-komponentteihin. Jos komponentissa on aikaisemmin käytetty jotakin otsikkotyyliä, on komponentti visuaalisesti muuttunut päivityksen myötä. Usein tämä tarkoittaa pieniä korkeus-, leveys- ja välistysmuutoksia komponentissa.`}</p>
    <p>{`Voit lukea kirjasinmuutoksista ja niiden vaikutuksista tarkemmin `}<a parentName="p" {...{
        "href": "/getting-started/hds-2.0/migrating-to-2.0"
      }}>{`2.0 päivitysoppaasta (englanniksi)`}</a>{`.`}</p>
    <Image src="/images/getting-started/hds-2.0/typography-before-after-fi.png" alt="Kirjasintyylit - Ennen ja jälkeen -kuvat" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "haitari-accordion--muutokset",
      "style": {
        "position": "relative"
      }
    }}>{`Haitari (accordion) -muutokset`}<a parentName="h3" {...{
        "href": "#haitari-accordion--muutokset",
        "aria-label": "haitari accordion  muutokset permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Hel.fi-uudistuksen tekemät muutokset `}<a parentName="p" {...{
        "href": "/components/accordion"
      }}>{`haitari-komponenttiin (englanniksi)`}</a>{` on tuotu osaksi HDS:iä. Komponentti tukee nyt valinnaista sulkupainiketta, joka sijoittuu avatun haitarin alaosaan. Komponentti tukee nyt myös kolmea (3) kokovaihtoehtoa.`}</p>
    <Image src="/images/getting-started/hds-2.0/accordion-before-after-fi.png" alt="Haitarikomponentti - Ennen ja jälkeen -kuvat" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "breakpoint-token-ja-sisältö-container--muutokset",
      "style": {
        "position": "relative"
      }
    }}>{`Breakpoint-token ja sisältö-container -muutokset`}<a parentName="h3" {...{
        "href": "#breakpoint-token-ja-sis%C3%A4lt%C3%B6-container--muutokset",
        "aria-label": "breakpoint token ja sisältö container  muutokset permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS Breakpoint -ohjeistukset ovat muuttuneet. Breakpoint-arvot ovat pysyneet ennallaan, mutta sisältöalueen leveys on muuttunut. Päivityksen jälkeen on määritelty ainoastaan yksi maksimileveys sisältöalueelle, 1200 pikseliä. Muilla ruutuleveyksillä sisältöalue hyödyntää kaiken tarjolla olevan tilan ja ulkopuoliset marginaalit pysyvät samoina. Tämä muutos parantaa tilankäyttöä erityisesti pienillä ruuduilla. Helpoin tapa siirtyä seuraamaan uutta ohjeistusta on käyttää `}<Link size="M" href="/storybook/react/?path=/story/components-container--example" mdxType="Link">{`HDS Container -komponenttia (englanniksi)`}</Link>{`.`}</p>
    <Image src="/images/getting-started/hds-2.0/container-before-after-fi.png" alt="Sisältöalueen leveys - Ennen ja jälkeen -kuvat" style={{
      "display": "block",
      "width": "100%",
      "minWidth": "600px",
      "maxWidth": "915px",
      "margin": "0 auto"
    }} viewable mdxType="Image" />
    <h3 {...{
      "id": "muita-pienempiä-muutoksia",
      "style": {
        "position": "relative"
      }
    }}>{`Muita pienempiä muutoksia`}<a parentName="h3" {...{
        "href": "#muita-pienempi%C3%A4-muutoksia",
        "aria-label": "muita pienempiä muutoksia permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Supplementary-painikkeen välistysmuutokset`}</li>
      <li parentName="ul">{`Linkki-komponentin oletuskoko muutettu koosta S kokoon M`}</li>
      <li parentName="ul">{`Harmaasävyn väriarvoja on hieman muutettu`}</li>
      <li parentName="ul">{`Teemamuuttujat, jotka on aikaisemmin merkitty vanhentuneiksi on poistettu`}</li>
    </ul>
    <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases/tag/v2.0.0" mdxType="ExternalLink">Lue koko muutosloki HDS:n GitHub -sivuilla (englanniksi)</ExternalLink>
    <h2 {...{
      "id": "mitä-tapahtuu-hds-v1--versioille",
      "style": {
        "position": "relative"
      }
    }}>{`Mitä tapahtuu HDS V1 -versioille?`}<a parentName="h2" {...{
        "href": "#mit%C3%A4-tapahtuu-hds-v1--versioille",
        "aria-label": "mitä tapahtuu hds v1  versioille permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`HDS-tiimi jatkaa V1-versioiden tukemista ainakin vuoden 2022 loppuun saakka. Uusia ominaisuuksia ei vanhaan versioon julkaista, mutta version käyttöä tuetaan ja tarvittaessa korjataan kriittisiä ongelmia, jotka estäisivät pakettien käytön projektissa. Toivomme, että mahdollisimman moni projekti siirtyisi käyttämään uutta versiota. Samalla kuitenkin ymmärrämme, että kaikilla projekteilla ei välttämättä ole kehitysresursseja tehdä päivitystä välittömästi.`}</p>
    <h3 {...{
      "id": "hds-v1-dokumentaatio",
      "style": {
        "position": "relative"
      }
    }}>{`HDS V1 dokumentaatio`}<a parentName="h3" {...{
        "href": "#hds-v1-dokumentaatio",
        "aria-label": "hds v1 dokumentaatio permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>Etsitkö HDS V1 dokumentaatiota? Löydät sen täältä: <ExternalLink href="https://hds.hel.fi/v1" openInNewTab mdxType="ExternalLink">HDS V1 dokumentaatio</ExternalLink>.</p>
    <h3 {...{
      "id": "miten-hds-v1--kirjastoa-käytetään-abstractissa",
      "style": {
        "position": "relative"
      }
    }}>{`Miten HDS V1 -kirjastoa käytetään Abstractissa?`}<a parentName="h3" {...{
        "href": "#miten-hds-v1--kirjastoa-k%C3%A4ytet%C3%A4%C3%A4n-abstractissa",
        "aria-label": "miten hds v1  kirjastoa käytetään abstractissa permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`HDS versiota 1 on mahdollista käyttää, jos projektisi ei aio siirtyä käyttämään HDS 2.0 -versiota vielä hetkeen. Olemme julkaisseet `}<ExternalLink href="https://share.goabstract.com/e0a49189-f9f7-470f-956b-c7217b576fb0" mdxType="ExternalLink">{`erillisen Abstract-projektin`}</ExternalLink>{`, joka sisältää HDS:n kirjastotiedostot viimeisimmästä version 1 päivityksestä (1.15). Voit linkittää projektiisi nämä kirjastot, jos haluat vielä jatkaa version 1 käyttöä.`}</p>
    <p>{`Abstract ei valitettavasti tarjoa helppoa keinoa kahden eri kirjastoversion välillä vaihtamiseen. Vaihtamista voi kuitenkin sujuvoittaa käyttämällä `}<ExternalLink href="https://ashung.github.io/Automate-Sketch/" mdxType="ExternalLink">{`Automate Sketch-lisäosaa`}</ExternalLink>{`.`}</p>
    <ol>
      <li parentName="ol">{`Asenna `}<ExternalLink href="https://ashung.github.io/Automate-Sketch/" mdxType="ExternalLink">{`Automate-lisäosa`}</ExternalLink>{` Sketchiin.`}</li>
      <li parentName="ol">{`Poista projektisi linkitykset HDS-kirjastoihin Abstractissa.`}</li>
      <li parentName="ol">{`Linkitä `}<ExternalLink href="https://share.goabstract.com/e0a49189-f9f7-470f-956b-c7217b576fb0" mdxType="ExternalLink">{`HDS v1 -kirjastot`}</ExternalLink>{` projektiisi Abstractissa. Katso `}<Link href="getting-started/tutorials/abstract-tutorial#how-to-link-hds-libraries-to-a-project" mdxType="Link">{`Abstract oppaamme`}</Link>{`, jos tarvitset lisätietoja kirjastojen linkittämisestä.`}</li>
      <li parentName="ol">{`Avaa Abstractissa Sketch-dokumentti, joka käyttää HDS-kirjastojen komponentteja.`}</li>
      <li parentName="ol">{`Avaa Sketchissä "Plugins" -valikko ja valitse "Automate" → "Library" → "Replace library". Tämä korvaa symboli- ja tyyliviitteet HDS v1 kirjaston vastineilla. Muista tehdä commit muutoksista!`}</li>
      <li parentName="ol">{`Toista vaiheet 4 ja 5 jokaiselle Sketch-dokumentille projektissasi.`}</li>
    </ol>
    <p>{`Vaihtoehtoinen tapa on `}<ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases" mdxType="ExternalLink">{`ladata halutun HDS-version design kit -paketti julkaisulistasta`}</ExternalLink>{`. Tämä tapa ei ole suositeltu, mikäli projektia käytetään Abstractin kautta.`}</p>
    <h2 {...{
      "id": "miten-hds-versiot-tulevat-toimimaan-jatkossa",
      "style": {
        "position": "relative"
      }
    }}>{`Miten HDS-versiot tulevat toimimaan jatkossa?`}<a parentName="h2" {...{
        "href": "#miten-hds-versiot-tulevat-toimimaan-jatkossa",
        "aria-label": "miten hds versiot tulevat toimimaan jatkossa permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`HDS-projektin versiointi tulee pysymään ennallaan 2.0-päivityksen jälkeen. Seuraamme `}<ExternalLink href="https://semver.org/" mdxType="ExternalLink">{`semanttista versiointia (englanniksi)`}</ExternalLink>{` ja rikkovia muutoksia julkaistaan vain suurissa päivityksissä. Aiomme kuitenkin tehdä suuria julkaisuja enemmän tulevaisuudessa. Seuraava suuri julkaisu, HDS 3.0, on arvioitu julkaistavan syksyllä 2022.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      